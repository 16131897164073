import React from "react";
import { Menu, Dropdown, Avatar } from "antd";
import { connect } from "react-redux";
import { SettingOutlined, LogoutOutlined } from "@ant-design/icons";
import Icon from "components/util-components/Icon";
import { signOut } from "redux/actions/Auth";
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";
import Package from "../../../package.json";

const menuItem = [
  {
    title: "Definições",
    icon: SettingOutlined,
    path: "/home",
  },
];

export const NavProfile = ({ auth, logout }) => {
  const history = useHistory();
  //  const gleapUniqueId = `md-backoffice@${Package.version}::${auth?.data?.id}`;

  const staffPicture = (data) => {
    if (data === undefined || data === null) return null;
    if (data?.picture !== undefined) {
      return data?.picture;
    }
    return `https://api.dicebear.com/7.x/initials/svg?seed=${data?.name}`;
  };

  const profileMenu = (
    <div className="nav-profile nav-dropdown">
      <div className="nav-profile-header">
        <div className="d-flex">
          <Avatar size={45} src={staffPicture(auth?.data)} />
          <div className="pl-3">
            <h4 className="mb-0">{auth?.data?.name}</h4>
          </div>
        </div>
      </div>
      <div className="nav-profile-body">
        <Menu>
          {menuItem.map((el, i) => {
            return (
              <Menu.Item key={i}>
                <Link to={el.path}>
                  <Icon type={el.icon} />
                  <span className="font-weight-normal">{el.title}</span>
                </Link>
              </Menu.Item>
            );
          })}
          <Menu.Item
            key={menuItem.length + 1}
            onClick={(e) => {
              window.localStorage.removeItem("md-auth");
              window.localStorage.removeItem("authToken");

              history.push("/auth/login");
            }}
          >
            <span>
              <LogoutOutlined />
              <span className="font-weight-normal">Terminar Sessão</span>
            </span>
          </Menu.Item>
        </Menu>
      </div>
    </div>
  );
  return (
    <Dropdown placement="bottomRight" overlay={profileMenu} trigger={["click"]}>
      <Menu className="d-flex align-item-center" mode="horizontal">
        <Menu.Item key="profile">
          <Avatar src={staffPicture(auth?.data)} />
        </Menu.Item>
      </Menu>
    </Dropdown>
  );
};

export default connect(null, { signOut })(NavProfile);
